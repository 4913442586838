import React, {useEffect, useState} from "react";
import "./upload-form.css";
import {
  Button,
  Space,
  Form,
  TimePicker,
  ColorPicker,
  Input,
  Select,
  notification,
} from "antd";
import {
  direction,
  disabledHours,
  disabledMinutes,
} from "./upload-utlity";
import FontDropdown from './FontDropdown';
import GlobalFonts from './font';
import { useDispatch, useSelector } from "react-redux";
import { setFontSize, setFont, setColor } from "../../../../store/slices/textStylesSlice";
const availableFonts = [
  'AlexBrushRegular', 'AsapCondensedBlack', 'AsapCondensedBlackItalic', 'AsapCondensedBold', 'AsapCondensedBoldItalic',
  'AsapCondensedExtraLightItalic', 'AsapCondensedItalic', 'AsapCondensedMediumItalic', 'AsapCondensedRegular', 'AsapCondensedSemiBold',
  'CaveatBold', 'CaveatRegular', 'Caveat', 'CinzelBlack', 'CinzelBold', 'Cinzel', 'DancingScript', 'DancingScriptBold',
  'DancingScriptSemiBold', 'GFSDidotRegular', 'Inter', 'InterBold', 'InterExtraLight', 'LatoBlack', 'LatoBlackItalic',
  'LatoItalic', 'LatoLight', 'LatoLightItalic', 'LatoRegular', 'LobsterRegular', 'Oswald', 'OswaldBold', 'OswaldExtraLight',
  'OswaldLight', 'OswaldMedium', 'OswaldRegular', 'PacificoRegular', 'PlayfairDisplayBlack', 'PlayfairDisplayBoldItalic',
  'PlayfairDisplayItalic', 'PlayfairDisplayMedium', 'PlayfairDisplayMediumItalic', 'PlayfairDisplayRegular', 'PlayfairDisplaySemiBold',
  'PlayfairDisplaySemiBoldItalic', 'PTSansNarrowBold', 'PTSansNarrowRegular', 'PTSerifBold', 'PTSerifBoldItalic', 'PTSerifItalic',
  'PTSerifRegular', 'RobotoCondensedBold', 'RobotoCondensedBoldItalic', 'RobotoCondensedItalic', 'RobotoCondensedLight', 
  'RobotoCondensedLightItalic', 'RobotoCondensedRegular', 'TajawalBlack', 'TajawalBold', 'TajawalExtraBold', 'TajawalExtraLight',
  'TajawalLight', 'TajawalMedium', 'TajawalRegular', 'Vollkorn', 'VollkornBlack', 'VollkornBlackItalic', 'VollkornBold',
  'VollkornBoldItalic', 'VollkornExtraBold', 'VollkornExtraBoldItalic', 'VollkornItalic', 'VollkornMedium', 'VollkornMediumItalic',
  'VollkornRegular', 'VollkornSemiBold', 'VollkornSemiBoldItalic'
];



function VideoForm({
  videoData,
  endvideoTime,
  videoForm,
  textStyle,
  setTextStyle,
  updateVideoData,
  next,
  textBoxRef
}) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [selectedFont, setSelectedFont] = useState('AlexBrushRegular');
  const [clipStartTime, setStartTime] = useState();
  const [clipEndTime, setEndtime] = useState();
  const defaultColor = "#000000";

  const dispatch = useDispatch();
  
  const { fontSize, font, color } = useSelector((state) => state.textStyle);

  const handleFontChange = (selectedFont) => {
    setSelectedFont(selectedFont);
    setTextStyle({
      ...textStyle,
      font: selectedFont,
    });
    dispatch(setFont(selectedFont));
  };

  useEffect(()=>{
    if(videoData.font_size && videoData.font_color && videoData.font_type){
      setTextStyle({
        ...textStyle,
        fontSize: videoData.font_size,
      })
      setTextStyle({
        ...textStyle,
        color: videoData.font_color || defaultColor,
      });
      setTextStyle({
        ...textStyle,
        font: videoData.font_type,
      });
      setSelectedFont(videoData.font_type);
      setStartTime(videoData.startTime);
      setEndtime(videoData.endTime);
    }
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const convertToSeconds = (time) => {
    const parts = time.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const onFinish = (values) => {
    const startTime = values.start_time || videoData.startTime;
    const endTime = values.end_time || videoData.endTime;
    if(startTime == null || endTime == null){
      notification.error({
        message: "Please Enter Start time or End time!",
      });
      return;
    }
    if(font == null)
    {
      notification.error({
        message: "Please Select Font style!",
      });
      return;
    }

    if(fontSize===null)
    {

      notification.error({
        message: "Please Enter Font size!",
        description: "Font size should be in between 0 to 150.",
      });
      return;
    }

    if (startTime.$m > 0 || endTime.$m > 0) {
      startTime.$s += startTime.$m * 60;
      endTime.$s += endTime.$m * 60;
    }

    if (endTime.$s <= startTime.$s) {
      notification.error({
        message: "Invalid Time range!",
        description: "End time should be greater than the start time.",
      });
      return;
    }
    const videoEndtimeInSeconds = convertToSeconds(endvideoTime);

    if (endTime.$s > videoEndtimeInSeconds) {
      notification.error({
        message: "Invalid End time!",
        description: "End time cannot be greater than the video end time.",
      });
      return;
    }

    const video = document.querySelector(".video-container video");
    const originalHeight = video.videoHeight;
    const renderHeight = video.clientHeight;
    const originalWidth = video.videoWidth;
    const renderWidth = video.offsetWidth;
    const textareaHeight = textBoxRef.current.getBoundingClientRect().height * (originalHeight / renderHeight);
    const textareaWidth = textBoxRef.current.getBoundingClientRect().width * (originalWidth / renderWidth);

    updateVideoData("height_box", textareaHeight);
    updateVideoData("width_box", textareaWidth)
    updateVideoData("font_type", font);
    updateVideoData("font_color", color);
    updateVideoData("start_clip", startTime.$s);
    updateVideoData("end_clip", endTime.$s);
    updateVideoData("font_size", fontSize);
    updateVideoData("startTime", clipStartTime);
    updateVideoData("endTime", clipEndTime);

    notification.success({ message: "Video inserted successfully!" });
    next()
  };

  return (
    <div className="upload-form-container">
      <Form
        name="upload-video-form-header-text-form"
        form={videoForm}
        onFinish={onFinish}
      >
        <Space direction={"vertical"} size={5} block={true}>
          <div className="form-header-text">
            Select Duration in which you want to edit video {endvideoTime}
          </div>
          <Space
            direction={direction(windowWidth)}
            size={windowWidth >= 576 ? 20 : 0}
            block={true}
          >
            <Form.Item
              label="Start time : "
              name="start_time"
            >
              <TimePicker
                type={"time"}
                format="HH:mm:ss"
                disabledHours={() => disabledHours(endvideoTime)}
                disabledMinutes={() => disabledMinutes(endvideoTime)}
                onChange={(e)=>{setStartTime(e)}}
                defaultValue={videoData.startTime}
              />
            </Form.Item>
            <Form.Item
              label="End time : "
              name="end_time"
            >
              <TimePicker
                type={"time"}
                format="HH:mm:ss"
                disabledHours={() => disabledHours(endvideoTime)}
                disabledMinutes={() => disabledMinutes(endvideoTime)}
                onChange={(e)=>{setEndtime(e)}}
                defaultValue={videoData.endTime}
                value={clipEndTime || videoData.endTime}
              />
            </Form.Item>
          </Space>
          <div className="form-header-text">Set Colour & Text Settings</div>
          <Form.Item label="Select Font : " name="font">
            <GlobalFonts />
            <FontDropdown
              fonts={availableFonts}
              selectedFont={font}
              onChange={handleFontChange}
            />
          </Form.Item>
          <Form.Item
            label="Select Font Size : "
            name="font_size"
          >
            <Input
              type="number"
              placeholder="Enter Font Size"
              onChange={(e) => {
                dispatch(setFontSize(e?.target?.value))
              }}
              defaultValue={fontSize}
            />
          </Form.Item>
          <Form.Item
            label={"Select Colour : "}
            name="color"
          >
            <ColorPicker
              format={"hex"}
              onFormatChange={"hex"}
              defaultValue={color}
              onChange={(e) => {
                setTextStyle({
                  ...textStyle,
                  color: e?.metaColor?.toHexString(),
                });
                dispatch(setColor(e?.metaColor?.toHexString()));
              }}
            />
          </Form.Item>

          <Button
            className="prev-button"
            onClick={() => {
              videoForm.submit();
            }}
          >
            Next
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default VideoForm;
